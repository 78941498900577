<template>
  <div class="home">
    <h1>Josh's Website!</h1>
    <p>
      I am Josh Wright, sometimes known as wrightjjw or Weirdcko.
      I'm a programmer, gamer, and musician!
      This website is primarily a showcase and explanation of some of my work.
    </p>
    <p>
      This website is pretty new and still under construction.
      I'm still adding plenty of content for some of my projects on GitHub.
      I try to keep
      <a href="https://github.com/wrightjjw">my GitHub profile</a>
      updated with my recent work,
      and at the moment that will probably give you a better overview of my work.
    </p>
    <p>
      This website is written in <a href="https://vuejs.org">Vue</a>,
      hosted by <a href="https://firebase.google.com">Firebase</a>,
      and you can see the source and project management
      on <a href="https://github.com/wrightjjw/weirdcko.com">GitHub</a>!
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
